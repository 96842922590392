var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Box2.svg"}})],1),_c('h3',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.getTitle))]),_c('span',{class:[
                'ml-3 label label-xl font-weight-bold label-inline label-square',
                _vm.service.submittedForApproval && 'label-dark',
                _vm.service.accepted && 'label-success',
                _vm.service.rejected && 'label-warning'
              ]},[_vm._v(" "+_vm._s(_vm.service.status)+" ")])]),_c('div',{staticClass:"card-toolbar"},[(_vm.service.canBeSubmittedForApproval)?_c('button',{ref:"btnSubmitForApproval",staticClass:"btn btn-lg btn-icon btn-light-success m-2",attrs:{"title":_vm.$t('Submit for approval')},on:{"click":_vm.submitServiceForApproval}},[_c('i',{staticClass:"fas fa-check"})]):_vm._e(),_c('button',{staticClass:"btn btn-light-primary font-weight-bolder m-2",on:{"click":function($event){return _vm.$router.push({
                  name: 'user-services-edit',
                  params: {
                    id: _vm.$route.params.id
                  }
                })}}},[_c('i',{staticClass:"fa fa-edit icon-lg"}),_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")]),_c('button',{staticClass:"btn btn-light-dark font-weight-bolder m-2",on:{"click":function($event){return _vm.$router.push({ name: 'user-services' })}}},[_c('i',{staticClass:"ki ki-long-arrow-back icon-lg"}),_vm._v(" "+_vm._s(_vm.$t("Back to Services"))+" ")])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[(_vm.service.fileUrl !== null)?_c('div',{staticClass:"mb-5"},[_c('img',{attrs:{"src":_vm.service.fileUrl,"alt":"file","width":"100%"}}),_c('hr')]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.service.content)}})]),_c('div',{staticClass:"col-sm-4"},[(_vm.service.rejected)?_c('div',{staticClass:"alert alert-custom alert-notice alert-warning fade show m-0 mb-5",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-white mr-3"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Info-circle.svg"}})],1)]),_c('div',{staticClass:"alert-text text-justify font-weight-bold"},[_c('div',{domProps:{"innerHTML":_vm._s(
                      _vm.$t(
                        'Your service has been <strong>rejected by administrator</strong>. Please find below the reason why.'
                      )
                    )}}),_c('strong',[_vm._v("Reason :")]),_vm._v(" "+_vm._s(_vm.service.rejectedReason)),_c('br')])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-4 text-lg-right text-left"},[_vm._v(" "+_vm._s(_vm.$t("Category"))+" ")]),_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"form-control-plaintext font-weight-bold"},[_vm._v(" "+_vm._s(_vm.service.serviceCategory ? _vm.service.serviceCategory.label : null)+" ")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-4 text-lg-right text-left"},[_vm._v(" "+_vm._s(_vm.$t("Delivery Delay"))+" ")]),_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"form-control-plaintext font-weight-bold"},[_vm._v(" "+_vm._s(_vm.service.delayDisplay)+" ")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-4 text-lg-right text-left"},[_vm._v(" "+_vm._s(_vm.$t("Keywords"))+" ")]),_c('div',{staticClass:"col-8"},[(_vm.service.keywords)?_c('span',{staticClass:"form-control-plaintext font-weight-bold"},[_vm._l((_vm.service.keywords.split(',')),function(keyword,index){return [_c('span',{key:index,staticClass:"ml-3 label label-xl font-weight-bold label-inline label-square mb-2"},[_vm._v(" "+_vm._s(keyword)+" ")])]})],2):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-4 text-lg-right text-left"},[_vm._v(" "+_vm._s(_vm.$t("Published"))+" ")]),_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"form-control-plaintext font-weight-bold"},[_c('span',{class:[
                        'label label-lg font-weight-bold label-inline label-square',
                        _vm.service.published && 'label-light-success',
                        !_vm.service.published && 'label-light-warning'
                      ]},[_vm._v(" "+_vm._s(_vm.service.publishedDisplay)+" ")])])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-4 text-lg-right text-left"},[_vm._v(" "+_vm._s(_vm.$t("Created at"))+" ")]),_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"form-control-plaintext font-weight-bold"},[_vm._v(" "+_vm._s(_vm.service.createdAt)+" ")])])])])])])])])]),_c('service-options')],1)}
var staticRenderFns = []

export { render, staticRenderFns }